import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { ProfilePictureVariant, User } from 'Types/userprofile'
import { usePutSocialMediaMutation } from 'Utils/api'
import { useDialog } from 'Utils/DialogContext'
import {
  ImageCropResponse,
  useDeleteImageMutation,
  useGetUserQuery,
  usePutImageCropMutation,
  usePutImageMutation,
  usePutNameMutation,
} from 'Utils/api/user.api'
import { refreshUserProfile } from '@trr/app-shell-communication'
import { useToaster } from 'Utils/ToasterContext'
import getConfig from 'Utils/config'

export interface UserContextValues {
  user: Partial<User>
  setUserData?: (updatedUser: Partial<User>) => void
  hasProfilePicture: boolean
  hasUserLoaded?: boolean
  refetchUser?: () => Promise<unknown>
}

export interface UseUserInformation {
  user: Partial<User>
  setUserData?: (updatedUser: Partial<User>) => void
  getLinkedInAddress: () => string
  updateLinkedInAddress: (newLinkedIn: string) => void
  getUserFullName: () => string
  getUserInitials: () => string
  updateName: (firstName: string, lastName: string) => void
  hasProfilePicture: boolean
  hasUserLoaded?: boolean
  uploadUserProfilePicture: (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => void
  deleteProfilePicture: () => void
  updateImageCropData: (cropInformation: string) => void
  uncroppedProfilePictureUrl: string
}

export const UserContext = React.createContext<UserContextValues | undefined>(
  undefined
)

export interface UserProviderProps {
  profileImages?: ProfilePictureVariant
}

export const UserProvider = (props: PropsWithChildren<UserProviderProps>) => {
  const { children, profileImages } = props
  const [userData, setUserData] = useState<Partial<User>>({})

  const [hasProfilePicture, setHasProfilePicture] = useState(false)

  const { data, isSuccess, refetch, isFetching } = useGetUserQuery()

  useEffect(() => {
    if (!isFetching && isSuccess) {
      const user = data[0]
      setUserData({ ...user, profileImages })
      setHasProfilePicture(Boolean(user.imageFileName))
    }
  }, [isSuccess, data, profileImages, isFetching])

  return (
    <UserContext.Provider
      value={{
        user: userData,
        setUserData,
        hasProfilePicture,
        hasUserLoaded: isSuccess,
        refetchUser: refetch,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserInformation = (): UseUserInformation => {
  const context = React.useContext(UserContext)
  const { MEDIA_URL } = getConfig()
  const { showToaster } = useToaster()

  if (context === undefined) {
    throw new Error('useUserInformation must be used within a UserProvider')
  }

  const { user, setUserData, hasProfilePicture, hasUserLoaded, refetchUser } =
    context
  const [putSocialMedia] = usePutSocialMediaMutation()
  const [putImage] = usePutImageMutation()
  const [deleteImage] = useDeleteImageMutation()
  const [putImageCrop] = usePutImageCropMutation()
  const { closeDialog } = useDialog()
  const [putName] = usePutNameMutation()

  const updateLinkedInAddress = (newLinkedInAccountUrl: string) => {
    const isLinkedInAccountEmpty = newLinkedInAccountUrl.trim() === ''
    const newLinkedInAccount = { type: 'linkedin', url: newLinkedInAccountUrl }
    // Backend wants an entirely empty array when removing social media account.
    // Posting an array with { type: linkedin, url: '' } will result in an error.
    putSocialMedia(isLinkedInAccountEmpty ? [] : [newLinkedInAccount])
      .unwrap()
      .then(() => {
        setUserData?.({ ...user, socialMedia: [newLinkedInAccount] })
        closeDialog()
      })
      .catch(() => {
        closeDialog()
        showToaster()
      })
  }

  const getLinkedInAddress = () => {
    return (
      user.socialMedia?.find((socialMedia) => socialMedia.type === 'linkedin')
        ?.url ?? ''
    )
  }

  const updateName = (newFirstName: string, newLastName: string) => {
    putName({
      firstName: newFirstName,
      lastName: newLastName,
    })
      .unwrap()
      .then(() => {
        setUserData?.({
          ...user,
          firstName: newFirstName,
          lastName: newLastName,
        })
        closeDialog()
      })
      .catch(() => {
        closeDialog()
        showToaster()
      })
  }

  const getUserFullName = (): string => {
    if (!user.firstName || !user.lastName) return ''

    return `${user.firstName} ${user.lastName}`
  }

  const getUserInitials = (): string => {
    if (!user.firstName || !user.lastName) return ''

    return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase()
  }

  const uploadUserProfilePicture = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ): void => {
    event?.preventDefault()

    if (!event?.target.files) return
    const formData = new FormData()
    formData.append('image', event.target.files[0])
    putImage(formData)
      .unwrap()
      .then(() => getUserProfile())
      .catch(() => showToaster())
  }

  const updateImageCropData = (cropInformation: string): void => {
    putImageCrop(cropInformation)
      .unwrap()
      .then((res: ImageCropResponse) => {
        setUserData?.({ ...user, imageFileName: res.imageFileName })
        getUserProfile()
      })
      .catch(() => showToaster())
  }
  const getUserProfile = (): void => {
    if (!refetchUser) return
    refetchUser()
      .then(() => {
        refreshUserProfile()
      })
      .catch(() => showToaster())
  }

  const deleteProfilePicture = (): void => {
    deleteImage()
      .then(() => getUserProfile())
      .catch(() => showToaster())
  }

  const uncroppedProfilePictureUrl: string = useMemo(() => {
    const mediaURL: string = MEDIA_URL ?? ''
    // Full url includes cropdata as query parameter.
    // Split and take the string prior to params for full image.
    const imageUrlWithoutCropData: string =
      user.imageFileName?.split('&')[0] ?? ''
    const profileImageEndpoint = 'user-profile-images'

    return `${mediaURL}/${profileImageEndpoint}/${imageUrlWithoutCropData}`
  }, [user.imageFileName, MEDIA_URL])

  return {
    user,
    setUserData,
    getLinkedInAddress,
    updateLinkedInAddress,
    getUserFullName,
    getUserInitials,
    hasProfilePicture,
    hasUserLoaded,
    uploadUserProfilePicture,
    deleteProfilePicture,
    updateImageCropData,
    uncroppedProfilePictureUrl,
    updateName,
  }
}

export default UserProvider
