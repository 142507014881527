import React from 'react'
import { ConsentBlock } from 'Types'
import { Switch } from '@mui/material'
import BaseSetting from 'Components/BaseSetting'
import { useConsent } from 'Utils/ConsentContext'

const Setting = ({ setting }: { setting: ConsentBlock }) => {
  const { consents, updateConsents } = useConsent()
  const consentKey = setting.properties.consent.consentKey ?? ''
  const isActive = consents?.[consentKey]

  return (
    <BaseSetting setting={setting}>
      <Switch
        checked={isActive ?? false}
        onChange={() => updateConsents(consentKey, !isActive)}
      />
    </BaseSetting>
  )
}

export default Setting
