import { Button, Grid, ListItem, Typography, useTheme } from '@mui/material'
import React from 'react'

interface ListGroupItemProps {
  label: string
  content?: string | JSX.Element
  hideChangeButton?: boolean
  onClick?: () => void
  linkTo?: string
  testId?: string
}

const ListGroupItem = (props: ListGroupItemProps) => {
  const { label, content, hideChangeButton, onClick, linkTo } = props
  const theme = useTheme()
  return (
    <ListItem divider sx={{ py: 1 }}>
      <Grid container minHeight="40px" justifyContent="space-between">
        <Grid item xs={12} md={2} alignSelf="center">
          <Typography variant="body1" color={theme.palette.text.secondary}>
            {label}
          </Typography>
        </Grid>
        <Grid
          md={9}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item zeroMinWidth xs={8}>
            <Typography
              noWrap
              variant="body1"
              data-testid={props.testId && `${props.testId}-value`}
            >
              {content}
            </Typography>
          </Grid>
          <Grid item>
            {hideChangeButton ? null : (
              <Button
                data-testid={props.testId && `${props.testId}-change-button`}
                href={linkTo}
                variant="text"
                size="small"
                onClick={onClick}
              >
                Ändra
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  )
}

export default ListGroupItem
