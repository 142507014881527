import React from 'react'
import { Button } from '@mui/material'
import BaseSetting from 'Components/BaseSetting'
import { ConsentBlock } from 'Types'
import { DialogVariants, useDialog } from 'Utils/DialogContext'
import { ConsentDialogContent } from 'Components/Dialogs/ConsentDialog/ConsentDialog'
import { useConsent } from 'Utils/ConsentContext'

const Consent = ({ consent }: { consent: ConsentBlock }) => {
  const { consents } = useConsent()
  const { openDialog } = useDialog()
  const { giveConsentButton, cancelConsentButton, consentKey } =
    consent.properties.consent
  const isActive = consents?.[consentKey] ?? false

  const toggleConsent = () => {
    openDialog<ConsentDialogContent>(DialogVariants.consent, {
      consent,
      isActive,
    })
  }

  return (
    <BaseSetting setting={consent}>
      <Button
        sx={{ width: 'max-content' }}
        data-testid="consent-button"
        variant="outlined"
        onClick={toggleConsent}
        size="small"
      >
        {(isActive && cancelConsentButton) || giveConsentButton}
      </Button>
    </BaseSetting>
  )
}

export default Consent
