import React from 'react'
import { Link, ListItem } from '@mui/material'
import { ConsentBlock } from 'Types'
import { DialogVariants, useDialog } from 'Utils/DialogContext'

import { ReadmoreDialogContent } from './ReadmoreDialog'

const ReadMoreRow = ({ setting }: { setting: ConsentBlock }) => {
  const { openDialog } = useDialog()

  return (
    <>
      <ListItem
        sx={{
          justifyContent: 'left',
        }}
        divider={false}
        disableGutters
      >
        <Link
          sx={{ textAlign: 'left' }}
          component="button"
          onClick={() =>
            openDialog<ReadmoreDialogContent>(DialogVariants.readmore, {
              title: setting.properties.consent.consentModalHeading,
              text: setting.properties.consent.consentModalEditor,
            })
          }
        >
          {setting.properties.consent.readMoreLinkText}
        </Link>
      </ListItem>
    </>
  )
}

export default ReadMoreRow
