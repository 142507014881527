import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import React, { useState } from 'react'
import { EpiContent } from 'Types'
import { DialogVariants, useDialog } from 'Utils/DialogContext'
import { useUserInformation } from 'Utils/UserContext'

const LinkedInDialog = () => {
  const { dialogType, closeDialog } = useDialog()
  const { updateLinkedInAddress, getLinkedInAddress } = useUserInformation()

  const [newLinkedInAccount, setNewLinkedInAccount] =
    React.useState(getLinkedInAddress())

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { linkedInDialogContent } = useEpiContent<EpiContent>()

  const handleSave = () => {
    const validLinkedInFormat = new RegExp(
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm
    )

    const trimmedLinkedInAccount = newLinkedInAccount.trim()

    const isInvalidNonEmptyString =
      validLinkedInFormat.test(trimmedLinkedInAccount) === false &&
      trimmedLinkedInAccount !== ''

    if (isInvalidNonEmptyString)
      return setErrorMessage('Felaktigt format på LinkedIn-adress')

    if (trimmedLinkedInAccount === getLinkedInAddress()) {
      return closeDialog()
    }

    updateLinkedInAddress(trimmedLinkedInAccount)
  }

  const handleCancel = () => {
    closeDialog()
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setNewLinkedInAccount(event.target.value)
  }

  return (
    <Dialog
      open={dialogType === DialogVariants.linkedIn}
      onClose={closeDialog}
      fullWidth
    >
      <DialogTitle>{linkedInDialogContent.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          {linkedInDialogContent.body}
        </DialogContentText>
        <TextField
          label={linkedInDialogContent.inputLabel}
          value={newLinkedInAccount ?? ''}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          fullWidth
          inputProps={{
            'data-testid': 'linkedin-url',
          }}
          FormHelperTextProps={{
            // @ts-expect-error testid can be propagated to element but is not yet part of type definition
            'data-testid': 'linkedin-errormsg',
          }}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>
          Avbryt
        </Button>
        <Button
          variant="text"
          data-testid="linkedin-savebtn"
          onClick={handleSave}
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LinkedInDialog
