import { Box, List, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'

interface ListGroupProps {
  title: string
}

const ListGroup = ({ title, children }: PropsWithChildren<ListGroupProps>) => {
  return (
    <Box component="article" sx={{ marginBottom: 7 }}>
      <Typography variant="h5" marginBottom={1} data-testid="listgroup-title">
        {title}
      </Typography>
      <List disablePadding>{children}</List>
    </Box>
  )
}

export { ListGroup }
