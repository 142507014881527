import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { HTMLMapper } from '@trr/html-mapper'
import { DialogVariants, useDialog } from 'Utils/DialogContext'
import getConfig from 'Utils/config'

export interface ReadmoreDialogContent {
  title: string
  text: string
}

const ReadmoreDialog = () => {
  const { dialogType, closeDialog, content } = useDialog()

  const dialogContent = content as ReadmoreDialogContent

  return (
    <>
      {dialogType === DialogVariants.readmore && (
        <Dialog
          open={dialogType === DialogVariants.readmore}
          onClose={closeDialog}
        >
          <DialogTitle>{dialogContent?.title}</DialogTitle>
          <DialogContent>
            <HTMLMapper
              mediaUrl={getConfig().MEDIA_URL}
              body={dialogContent?.text}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={closeDialog}>
              Stäng
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default ReadmoreDialog
