import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions, defaultLocale } from '@trr/mui-theme'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import SettingsViewContainer from 'Components/SettingsViewContainer'
import { ApiProvider } from '@reduxjs/toolkit/query/react'
import { DialogProvider } from 'Utils/DialogContext'
import { UserProvider } from 'Utils/UserContext'
import { publicWebApi } from 'Utils/api'
import { ProfilePictureVariant } from 'Types/userprofile'
import { ConsentProvider } from 'Utils/ConsentContext'
import ToasterProvider from 'Utils/ToasterContext'

const App = ({
  appShellData,
  profileImages,
}: {
  appShellData: AppShellDataProps
  profileImages: ProfilePictureVariant
}) => {
  const theme = createTheme(themeOptions, defaultLocale)

  return (
    <ToasterProvider>
      <ApiProvider api={publicWebApi}>
        <AppShellDataProvider value={appShellData}>
          <ThemeProvider theme={theme}>
            <UserProvider profileImages={profileImages}>
              <DialogProvider>
                <ConsentProvider>
                  <SettingsViewContainer />
                </ConsentProvider>
              </DialogProvider>
            </UserProvider>
          </ThemeProvider>
        </AppShellDataProvider>
      </ApiProvider>
    </ToasterProvider>
  )
}

export default App
