import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react/'
import { getIdToken } from '@trr/app-shell-data'
import getConfig from 'Utils/config'

const { PUBLIC_WEB_API_URL, MEDIA_URL } = getConfig()

const prepareHeaders = (headers: Headers) => {
  headers.set('Content-Type', 'application/json')
  headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
  headers.set('Accept-Language', 'sv')
  return headers
}

export const publicWebApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: PUBLIC_WEB_API_URL,
    prepareHeaders: (headers, { endpoint }) => {
      const UPLOAD_ENDPOINTS = ['putImage']
      // File upload is not possible if content type is application/json
      if (!UPLOAD_ENDPOINTS.includes(endpoint)) {
        headers.set('Content-Type', 'application/json')
      }

      headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
      headers.set('Accept-Language', 'sv')
      return headers
    },
  }),
  tagTypes: ['Communications'],
  endpoints: () => ({}),
})

export const mediaBaseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: MEDIA_URL,
    prepareHeaders,
  }),
  endpoints: () => ({}),
})
