import { useToaster } from 'Utils/ToasterContext'
import { useUserInformation } from 'Utils/UserContext'
import { usePutConsentMutation } from 'Utils/api'
import React, { PropsWithChildren, useState } from 'react'

export interface ConsentContextValues {
  consents?: Record<string, boolean>
  updateConsents: (consentKey: string, isActive: boolean) => void
}

export const ConsentContext = React.createContext<
  ConsentContextValues | undefined
>(undefined)

export const useConsent = () => {
  const context = React.useContext(ConsentContext)

  if (!context)
    throw new Error('useConsent must be used within a ConsentProvider')
  return context
}

export const ConsentProvider = (props: PropsWithChildren) => {
  const { children } = props
  const { user } = useUserInformation()
  const { showToaster } = useToaster()
  const [putConsent] = usePutConsentMutation()
  const consents = user.klientProperties?.consent

  const [allConsents, setAllConsents] = useState<Record<string, boolean>>(
    consents ?? {}
  )

  const updateConsents = (consentKey: string, isActive: boolean) => {
    setAllConsents({
      ...allConsents,
      ...{ [consentKey]: isActive },
    })
    putConsent({ endpoint: consentKey, body: { consent: isActive } })
      .unwrap()
      .catch(() => {
        showToaster()
        setAllConsents({
          ...allConsents,
          ...{ [consentKey]: !isActive },
        })
      })
  }

  React.useEffect(() => consents && setAllConsents(consents), [consents])

  return (
    <ConsentContext.Provider
      value={{
        consents: allConsents,
        updateConsents,
      }}
    >
      {children}
    </ConsentContext.Provider>
  )
}
