import React, { PropsWithChildren, useState } from 'react'
import { IconButton, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export interface ToasterContextValues {
  showToaster: (message?: string) => void
}

export const ToasterContext = React.createContext<
  ToasterContextValues | undefined
>(undefined)

export const ToasterProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false)
  const [toasterMessage, setToasterMessage] = useState<string | undefined>()
  const defaultMessage = 'Ett fel inträffade, var vänlig försök igen'

  const onClose = () => {
    setIsOpen(false)
    setToasterMessage('')
  }

  const showToaster = (message?: string) => {
    setToasterMessage(message ?? defaultMessage)
    setIsOpen(true)
  }

  const snackBarAction = (
    <IconButton
      data-testid="toaster-button"
      size="small"
      aria-label="close"
      color="inherit"
      onClick={onClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )

  return (
    <ToasterContext.Provider
      value={{
        showToaster: showToaster,
      }}
    >
      {children}
      <Snackbar
        data-testid="toaster"
        open={isOpen}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClose}
        action={snackBarAction}
        message={toasterMessage}
      />
    </ToasterContext.Provider>
  )
}

export const useToaster = () => {
  const context = React.useContext(ToasterContext)

  if (context === undefined) {
    throw new Error('useToaster must be used within a ToasterProvider')
  }

  const { showToaster } = context

  return {
    showToaster,
  }
}

export default ToasterProvider
