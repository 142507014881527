import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import CommunicationList from 'Components/CommunicationList/CommunicationList'
import ConsentList from 'Components/Consent'
import VisibilitySettings from 'Components/VisibilitySettings/VisibilitySettings'
import { useEpiContent } from '@trr/app-shell-data'
import DialogContainer from 'Components/Dialogs/DialogContainer/DialogContainer'
import Kontaktuppgifter from 'Components/Kontaktuppgifter'
import React from 'react'
import { EpiContent } from 'Types'
import Personuppgifter from 'Components/Personuppgifter/Personuppgifter'
import Profilbild from 'Components/Profilbild/Profilbild'
import { useUserInformation } from 'Utils/UserContext'

const SettingsViewContainer = () => {
  const { settingsContent } = useEpiContent<EpiContent>()
  const theme = useTheme()
  const { hasUserLoaded } = useUserInformation()

  return hasUserLoaded ? (
    <Box maxWidth={'750px'}>
      <Typography variant="h2">{settingsContent.heading}</Typography>
      <Typography
        variant="body1"
        color={theme.palette.text.secondary}
        marginBottom={7}
      >
        {settingsContent.ingress}
      </Typography>
      <Kontaktuppgifter />
      <Personuppgifter />
      <Profilbild />
      <DialogContainer />
      <CommunicationList />
      <VisibilitySettings />
      <ConsentList />
    </Box>
  ) : (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default SettingsViewContainer
