import React from 'react'
import { Communication } from 'Types'

import CommunicationItem from './CommunicationItem'

export interface CommunicationSectionProps {
  communicationOptions: Communication[]
  callback: (id: string) => (isActive: boolean) => Promise<boolean>
}

const CommunicationSection = ({
  communicationOptions,
  callback,
}: CommunicationSectionProps) => {
  return (
    <>
      {communicationOptions.map((communication: Communication) => (
        <CommunicationItem
          key={communication.id}
          communication={communication}
          callback={callback(communication.id)}
        />
      ))}
    </>
  )
}

export default CommunicationSection
