import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { DialogVariants, useDialog } from 'Utils/DialogContext'
import { useUserInformation } from 'Utils/UserContext'
import { useEpiContent } from '@trr/app-shell-data'
import { EpiContent } from 'Types'

const verifyStrings = (newFirstName: string, newLastName: string) => {
  let firstNameError = null
  let lastNameError = null

  // Alphabetical, including -, ., and ' but no multiple special characters, and no multiple spaces
  const nameValidator = /^[A-Za-zÀ-ž]+(['\-.\s]?[A-Za-zÀ-ž]+)*$/

  if (newFirstName.trim() === '') firstNameError = 'Förnamn får ej vara tomt'
  if (nameValidator.test(newFirstName) === false)
    firstNameError = 'Förnamn har felaktigt format'

  if (newLastName.trim() === '') lastNameError = 'Efternamn får ej vara tomt'
  if (nameValidator.test(newLastName) === false)
    lastNameError = 'Efternamn har felaktigt format'

  return { firstNameError, lastNameError }
}

const NameDialog = () => {
  const { dialogType, closeDialog } = useDialog()
  const { user, updateName } = useUserInformation()
  const { nameDialogContent } = useEpiContent<EpiContent>()

  const [newFirstName, setNewFirstName] = useState(user.firstName ?? '')
  const [newLastName, setNewLastName] = useState(user.lastName ?? '')

  const [errorFirstNameMessage, setErrorFirstNameMessage] = useState<
    null | string
  >(null)
  const [errorLastNameMessage, setErrorLastNameMessage] = useState<
    null | string
  >(null)

  const handleChangeFirstName = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    errorFirstNameMessage && setErrorFirstNameMessage(null)
    setNewFirstName(event.target.value)
  }

  const handleChangeLastName = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    errorLastNameMessage && setErrorLastNameMessage(null)
    setNewLastName(event.target.value)
  }

  const handleSave = () => {
    const { firstNameError, lastNameError } = verifyStrings(
      newFirstName,
      newLastName
    )

    if (firstNameError ?? lastNameError) {
      setErrorFirstNameMessage(firstNameError)
      setErrorLastNameMessage(lastNameError)
      return
    }

    updateName(newFirstName, newLastName)
  }
  const handleCancel = () => {
    closeDialog()
  }

  return (
    <Dialog
      open={dialogType === DialogVariants.name}
      onClose={closeDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{nameDialogContent?.heading}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography variant="body1">
          {nameDialogContent?.description}
        </Typography>
        <TextField
          label={nameDialogContent?.firstNameInputLabel}
          value={newFirstName}
          helperText={errorFirstNameMessage}
          error={Boolean(errorFirstNameMessage)}
          fullWidth
          inputProps={{
            'data-testid': 'firstname-input',
          }}
          FormHelperTextProps={{
            // @ts-expect-error testid can be propagated to element but is not yet part of type definition
            'data-testid': 'firstname-errormsg',
          }}
          onChange={handleChangeFirstName}
        />
        <TextField
          label={nameDialogContent?.lastNameInputLabel}
          value={newLastName}
          helperText={errorLastNameMessage}
          error={Boolean(errorLastNameMessage)}
          fullWidth
          inputProps={{
            'data-testid': 'lastname-input',
          }}
          FormHelperTextProps={{
            // @ts-expect-error testid can be propagated to element but is not yet part of type definition
            'data-testid': 'lastname-errormsg',
          }}
          onChange={handleChangeLastName}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>
          Avbryt
        </Button>
        <Button variant="text" data-testid="name-savebtn" onClick={handleSave}>
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NameDialog
