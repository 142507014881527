import React from 'react'
import { Switch } from '@mui/material'
import { SettingRow } from 'Components/BaseSetting'
import { Communication } from 'Types'
import { useToaster } from 'Utils/ToasterContext'

export interface CommunicationItemProps {
  communication: Communication
  callback: (isActive: boolean) => Promise<boolean>
}

const CommunicationItem = ({
  communication,
  callback,
}: CommunicationItemProps): JSX.Element => {
  const { showToaster } = useToaster()
  const [isActive, setActive] = React.useState<boolean>(
    communication.isEnabled ?? false
  )

  const onChange = () => {
    setActive(!isActive)
    callback(!isActive).catch(() => {
      setActive(isActive)
      showToaster()
    })
  }

  return (
    <SettingRow
      heading={communication.name ?? ''}
      description={communication.description ?? ''}
    >
      <Switch
        data-testid="communication-switch"
        disabled={!communication.canBeChanged}
        checked={isActive}
        onChange={onChange}
      />
    </SettingRow>
  )
}

export default CommunicationItem
