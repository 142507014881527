import React from 'react'
import { useGetCommunicationQuery } from 'Utils/api'
import { usePutCommunicationMutation } from 'Utils/api/user.api'
import { ListGroup } from 'Components/ListGroup'
import { Communications, Communication } from 'Types'
import { CircularProgress } from '@mui/material'
import { useToaster } from 'Utils/ToasterContext'

import CommunicationSection from './CommunicationSection'

export const findCommunicationAlternative = (
  commType: string,
  id: string,
  data: Communications
) => {
  let result = -1

  data[commType].forEach((comm: Communication, commId: number) => {
    if (comm.id === id) {
      result = commId
      return
    }
  })

  return result
}

const CommunicationList = (): JSX.Element => {
  const { currentData, fulfilledTimeStamp, isLoading } =
    useGetCommunicationQuery()
  const [putCommunication] = usePutCommunicationMutation()
  const { showToaster } = useToaster()

  /**
   * sends the updated communication settings to backend
   * @param commType {string} - type of communication, at this time email or text (SMS I presume)
   * @param id {string} - id of communication alternative to update
   * @returns void
   */
  const updateCommunicationSettings =
    (commType: string) =>
    (id: string) =>
    (isActive: boolean): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        if (currentData && fulfilledTimeStamp) {
          const idInSettings = findCommunicationAlternative(
            commType,
            id,
            currentData
          )

          if (idInSettings < 0) {
            console.error(
              'updateCommunicationSettings: no setting found for id',
              id
            )
            resolve(false)
          }

          const settingToUpdate = currentData[commType][idInSettings]
          const isEnabled = isActive

          const newSetting = {
            ...settingToUpdate,
            isEnabled,
          }

          const settingsOfTypeToUpdate = [...currentData[commType]]

          settingsOfTypeToUpdate[idInSettings] = newSetting
          const newSettingsState = {
            ...currentData,
            [commType]: settingsOfTypeToUpdate,
          }

          putCommunication(newSettingsState)
            .unwrap()
            .then(() => {
              resolve(isEnabled)
            })
            .catch(() => {
              showToaster()
              reject(settingToUpdate?.isEnabled)
            })
        }
      })
    }

  if (isLoading) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const currentKeys = (currentData && Object.keys(currentData)) || []
  const hasCommunications = currentData
    ? Object.values(currentData).some((category) => category.length > 0)
    : false

  return (
    <>
      {hasCommunications && currentKeys.length > 0 && (
        <ListGroup title="Utskick">
          {currentKeys.map((type) => (
            <div key={type}>
              {currentData && (
                <CommunicationSection
                  communicationOptions={currentData[type]}
                  callback={updateCommunicationSettings(type)}
                />
              )}
            </div>
          ))}
        </ListGroup>
      )}
    </>
  )
}

export default CommunicationList
