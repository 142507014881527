import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { EpiContent } from 'Types'
import { ListGroup } from 'Components/ListGroup'
import ReadMoreList from 'Components/ReadMoreList'

import Setting from './Setting'

const VisibilitySettings = (): JSX.Element => {
  const { delningar } = useEpiContent<EpiContent>()

  return (
    <ListGroup title={delningar.name}>
      {delningar.contentBlockArea?.map((setting) => (
        <div key={setting.id} style={{ width: '100%' }}>
          <Setting setting={setting} />
        </div>
      ))}
      <ReadMoreList settings={delningar.contentBlockArea} />
    </ListGroup>
  )
}

export default VisibilitySettings
