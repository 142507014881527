import { Box, ListItem, Typography, useTheme } from '@mui/material'
import React, { PropsWithChildren } from 'react'

export enum Setter {
  Button,
  Switch,
}

interface SettingRowProps {
  heading: string
  description: string
  readmoreLink?: string
  readmoreHeader?: string
  readmoreText?: string
}

const SettingRow = ({
  heading,
  description,
  children,
}: PropsWithChildren<SettingRowProps>) => {
  const theme = useTheme()

  return (
    <ListItem divider sx={{ py: 2 }}>
      <div
        data-testid="setting-row"
        style={{
          width: '100%',
        }}
      >
        <Box
          data-testid="setting"
          sx={{
            pr: 2,
            columnGap: '100px',
            flexDirection: { md: 'row', xs: 'column' },
            display: 'flex',
            rowGap: '32px',
            justifyContent: 'space-between',
          }}
        >
          <Box data-testid="description">
            <Typography variant="body1">{heading}</Typography>
            <Typography color={theme.palette.text.secondary} variant="body2">
              {description}
            </Typography>
          </Box>
          <Box data-testid="setter">{children}</Box>
        </Box>
      </div>
    </ListItem>
  )
}

export default SettingRow
