import { useEpiContent } from '@trr/app-shell-data'
import { ListGroup } from 'Components/ListGroup'
import ListGroupItem from 'Components/ListGroupItem'
import React from 'react'
import { EpiContent } from 'Types'
import { DialogVariants, useDialog } from 'Utils/DialogContext'
import { useUserInformation } from 'Utils/UserContext'

const Kontaktuppgifter = () => {
  const { kontaktUppgifter } = useEpiContent<EpiContent>()
  const { openDialog } = useDialog()
  const { user, getLinkedInAddress } = useUserInformation()

  return (
    <ListGroup title={kontaktUppgifter.heading}>
      <ListGroupItem
        label={kontaktUppgifter.email}
        content={user.email}
        linkTo={kontaktUppgifter.changeEmailUrl}
      />
      <ListGroupItem
        label={kontaktUppgifter.phoneNumber}
        content={user.mobilePhone}
        linkTo={kontaktUppgifter.changePhoneNumberUrl}
      />
      <ListGroupItem
        label={kontaktUppgifter.linkedIn}
        content={getLinkedInAddress()}
        onClick={() => openDialog(DialogVariants.linkedIn)}
      />
    </ListGroup>
  )
}

export default Kontaktuppgifter
