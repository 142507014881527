import React, { PropsWithChildren, useState } from 'react'

export enum DialogVariants {
  linkedIn = 'linkedin',
  profilePicture = 'profilePicture',
  consent = 'consent',
  readmore = 'readmore',
  name = 'name',
}

export interface DialogContextValues {
  dialogType: DialogVariants | null
  content?: unknown
  openDialog: <T>(dialogType: DialogVariants, content?: T) => void
  closeDialog: () => void
}

export const DialogContext = React.createContext<
  DialogContextValues | undefined
>(undefined)

export const DialogProvider = (props: PropsWithChildren) => {
  const { children } = props
  const [dialogContent, setContent] = useState<unknown>()

  const [dialogType, setDialogType] = useState<DialogVariants | null>(null)
  const openDialog = (dialogType: DialogVariants, content: unknown) => {
    setDialogType(dialogType)
    setContent(content)
  }
  const closeDialog = () => setDialogType(null)
  return (
    <DialogContext.Provider
      value={{ dialogType, openDialog, closeDialog, content: dialogContent }}
    >
      {children}
    </DialogContext.Provider>
  )
}

export const useDialog = () => {
  const context = React.useContext(DialogContext)

  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider')
  }

  return context
}
