import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { EpiContent } from 'Types'
import { ListGroup } from 'Components/ListGroup'

import Consent from './Consent'

const ConsentList = (): JSX.Element => {
  const { samtycken } = useEpiContent<EpiContent>()

  return (
    <>
      {samtycken.contentBlockArea.length > 0 && (
        <ListGroup title={samtycken.name}>
          {samtycken.contentBlockArea?.map((consent) => (
            <div key={consent.id} style={{ width: '100%' }}>
              <Consent consent={consent} />
            </div>
          ))}
        </ListGroup>
      )}
    </>
  )
}

export default ConsentList
