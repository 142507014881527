import { List } from '@mui/material'
import { ConsentBlock } from 'Types'
import React from 'react'

import ReadMoreRow from './ReadMoreRow'

const ReadMoreList = ({ settings }: { settings: ConsentBlock[] }) => {
  return (
    <>
      <List sx={{ mb: 4 }}>
        {settings.map((setting: ConsentBlock) => (
          <ReadMoreRow setting={setting} key={setting.id} />
        ))}
      </List>
    </>
  )
}

export default ReadMoreList
