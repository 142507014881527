import React from 'react'
import { useDialog } from 'Utils/DialogContext'
import ReadmoreDialog from 'Components/ReadMoreList/ReadmoreDialog'

import LinkedInDialog from '../LinkedInDialog'
import ProfilePictureDialog from '../ProfilePictureDialog/ProfilePictureDialog'
import ConsentDialog from '../ConsentDialog/ConsentDialog'
import NameDialog from '../NameDialog'

const DialogContainer = () => {
  const { dialogType } = useDialog()

  if (dialogType === null) return null

  return (
    <>
      <LinkedInDialog />
      <ProfilePictureDialog />
      <ConsentDialog />
      <ReadmoreDialog />
      <NameDialog />
    </>
  )
}

export default DialogContainer
