import { useEpiContent } from '@trr/app-shell-data'
import { ListGroup } from 'Components/ListGroup'
import ListGroupItem from 'Components/ListGroupItem'
import React from 'react'
import { EpiContent } from 'Types'
import { useGetUserQuery } from 'Utils/api/user.api'
import { DialogVariants, useDialog } from 'Utils/DialogContext'
import { useUserInformation } from 'Utils/UserContext'

const Personuppgifter = () => {
  const { personUppgifter } = useEpiContent<EpiContent>()

  const { openDialog } = useDialog()
  const { getUserFullName } = useUserInformation()

  // Using getUserQuery and trying to access the [0]th
  // entry of the array returns error that number[0]
  // has no field named socialSecurityNumber. Refactor this
  const { data: socialSecurityNumber } = useGetUserQuery(undefined, {
    selectFromResult: ({ data: result }) => ({
      data: result?.find((_, index) => index === 0)?.socialSecurityNumber,
    }),
  })

  return (
    <ListGroup title={personUppgifter.heading}>
      <ListGroupItem
        label={personUppgifter.name}
        content={getUserFullName()}
        onClick={() => openDialog(DialogVariants.name)}
        testId="name"
      />
      <ListGroupItem
        label={personUppgifter.socialSecurityNumber}
        content={socialSecurityNumber}
        hideChangeButton
      />
    </ListGroup>
  )
}

export default Personuppgifter
