import { Communications, SocialMedia } from 'Types'
import { User } from '@trr/app-shell-data'

import { publicWebApi } from './baseApi'

export interface Options<T> {
  endpoint: string
  body: T
}

export interface UserResponse extends User {
  socialSecurityNumber: string
  imageFileName: string
}

export interface ImageCropResponse {
  imageFileName: string
}

const putQuery = <T>(endpoint: string) => ({
  query: (body: T) => {
    return {
      url: `/user/${endpoint}`,
      method: 'PUT',
      body,
    }
  },
})

export const userApi = publicWebApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCommunication: builder.query<Communications, void>({
      query: () => ({
        url: '/user/communication',
        method: 'GET',
      }),
    }),
    getUser: builder.query<UserResponse[], void>({
      query: () => ({
        url: '/user',
        method: 'GET',
      }),
    }),
    putUser: builder.mutation<void, Options<Partial<User>>>({
      query: (options: Options<Partial<User>>) => {
        return {
          url: `/user/${options.endpoint}`,
          method: 'PUT',
          body: options.body,
        }
      },
    }),
    putConsent: builder.mutation<void, Options<{ consent: boolean }>>({
      query: (options: Options<{ consent: boolean }>) => {
        return {
          url: `/user/consent/${options.endpoint}`,
          method: 'PUT',
          body: options.body,
        }
      },
    }),
    putCommunication: builder.mutation<void, Communications>(
      putQuery<Communications>('communication')
    ),
    putImage: builder.mutation(putQuery('image')),
    putImageCrop: builder.mutation<ImageCropResponse, string>(
      putQuery<string>('image/crop')
    ),
    putName: builder.mutation<void, { firstName: string; lastName: string }>(
      putQuery<{ firstName: string; lastName: string }>('contact/name')
    ),
    putSocialMedia: builder.mutation<void, SocialMedia[]>(
      putQuery<SocialMedia[]>('social-media')
    ),
    deleteImage: builder.mutation<void, void>({
      query: () => ({
        url: '/user/image',
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetCommunicationQuery,
  useGetUserQuery,
  usePutUserMutation,
  usePutCommunicationMutation,
  usePutImageMutation,
  usePutImageCropMutation,
  useDeleteImageMutation,
  usePutConsentMutation,
  usePutNameMutation,
  usePutSocialMediaMutation,
} = userApi
