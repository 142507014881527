import { ConsentBlock } from 'Types'
import React, { PropsWithChildren } from 'react'

import SettingRow from './SettingRow'

export enum Setter {
  Button,
  Switch,
}

interface BaseSettingProps {
  setting: ConsentBlock
}

const BaseSetting = ({
  setting,
  children,
}: PropsWithChildren<BaseSettingProps>) => {
  return (
    <SettingRow
      heading={setting.properties.consent.heading}
      description={setting.properties.consent.preamble}
      readmoreLink={setting.properties.consent.readMoreLinkText}
      readmoreHeader={setting.properties.consent.consentModalHeading}
      readmoreText={setting.properties.consent.consentModalEditor}
    >
      {children}
    </SettingRow>
  )
}

export default BaseSetting
